$font-size-base: 1rem;
$font-size-lg: ($font-size-base * 1.25);
$font-size-sm: ($font-size-base * 0.875);
$input-height: 2.375rem;
$input-height-sm: 1.9375rem;
$input-height-lg: 3rem;
$input-btn-focus-width: 0.2rem;
$custom-control-indicator-bg: #dee2e6;
$custom-control-indicator-disabled-bg: #e9ecef;
$custom-control-description-disabled-color: #868e96;
$white: white;
$theme-colors: (
  'primary': #08d,
);

//
// These variables can be used to customize the switch component.
//
$switch-height: 30px;
$switch-height-sm: calc(#{$input-height-sm} * 0.8) !default;
$switch-height-lg: calc(#{$input-height-lg} * 0.8) !default;
$switch-border-radius: $switch-height !default;
$switch-bg: $custom-control-indicator-bg !default;
$switch-checked-bg: map-get($theme-colors, 'primary') !default;
$switch-disabled-bg: $custom-control-indicator-disabled-bg !default;
$switch-disabled-color: $custom-control-description-disabled-color !default;
$switch-thumb-bg: $white !default;
$switch-thumb-border-radius: 50% !default;
$switch-thumb-padding: 4px !default;
$switch-focus-box-shadow: 0 0 0 $input-btn-focus-width rgba(map-get($theme-colors, 'primary'), 0.25);
$switch-transition: 0.2s all !default;

.switch {
  font-size: $font-size-base;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .lang {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 9.5px;
    height: 1px;
    width: 1px;
  }

  input {
    position: absolute;
    height: 1px;
    width: 1px;
    background: none;
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    padding: 0;

    + label {
      position: relative;
      min-width: calc(#{$switch-height} * 2);
      border-radius: $switch-border-radius;
      height: $switch-height;
      display: inline-block;
      cursor: pointer;
      outline: none;
      user-select: none;
      vertical-align: middle;
      font-size: 13px;
      font-weight: 500;
      line-height: 150%;
      text-indent: 4px;
      color: white;
      // text-indent: calc(calc(#{$switch-height} * 2) + 0.5rem);
    }

    + label::before,
    + label::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(#{$switch-height} * 2);
      bottom: 0;
      display: block;
    }

    + label::before {
      content: 'ru';
      color: #AEAEAE;
      text-indent: calc(calc(#{$switch-height} * 1) + 0.5rem);
      padding-top: 4px;
      background-color: #f3f5fc;
      border-radius: $switch-border-radius;
      transition: $switch-transition;
    }

    + label::after {
      content: 'kz';
      top: $switch-thumb-padding;
      left: $switch-thumb-padding;
      width: calc(#{$switch-height} - calc(#{$switch-thumb-padding} * 2));
      height: calc(#{$switch-height} - calc(#{$switch-thumb-padding} * 2));
      border-radius: $switch-thumb-border-radius;
      background-color: #03aed2;
      transition: $switch-transition;
    }

    &:checked + label::before {
      background-color: #f3f5fc;
      content: 'kz';
      color: #AEAEAE;
      text-indent: 10px;
    }

    &:checked + label::after {
      margin-left: $switch-height;
      content: 'ru';
      color: white;
    }

    &:focus + label::before {
      outline: none;
      box-shadow: 0 0 0 0.15rem var(--color-accent-transparent);
    }

    &:disabled + label {
      color: $switch-disabled-color;
      cursor: not-allowed;
    }

    &:disabled + label::before {
      background-color: $switch-disabled-bg;
    }
  }

  + .switch {
    margin-left: 1rem;
  }
}
