.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: #fff;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 25px 68px;
  position: relative;

  @media screen and (max-width: 1100px) {
    padding: 25px 10px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 10px;
    padding: 10px 30px;
    border-radius: 50px;
  }
}
.menu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;

  &Item {
    &Link {
      color: #444;
      font-size: 14px;
      font-weight: 300;
      line-height: 140%;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
      @media screen and (max-width: 980px) {
        color: white;
        font-size: 23px;
        font-weight: 400;
        line-height: 28px;
      }

    }
  }

  @media screen and (max-width: 1041px) {
    gap: 20px;
  }

  @media screen and (max-width: 980px) {
    display: none;
  }
}
.goToPlatform {
  color: #444;
  font-size: 14px;
  font-weight: 300;
  line-height: 140%; /* 19.6px */
  text-decoration-line: underline;
  @media screen and (max-width: 480px) {
    display: none;
  }
}

.burger {
  display: none;
  border: none !important;
  box-sizing: border-box;
  li {
    padding: 0 0 0 12px !important;
  }
  @media (max-width: 980px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 30px;
    height: calc(85vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    overflow: hidden;
  }
}

.logo {
  display: none;
  @media (max-width: 980px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.menuItem {
  color: white;
}

.socials{
  display: flex;
  gap: 15px;
  .social{
    border-radius: 8px;
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .socialBased {
    background: white;
    border-radius: 8px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
  }
  @media (max-width: 767px) {
    align-items: end;
  }
}
.blueLink {
  color: #03AED2;
}

.languageSwitch {
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;
  }
}

.separator {
  color: white;
}

.activeLanguage {
  color: #ffd966;
  text-decoration: underline;
  text-decoration-color: #ffd966;
}

.inactiveLanguage {
  color: white;
  text-decoration: none;
}

.wrap {
  @media (max-width: 980px) {
   display: flex;
   justify-content: space-between;
   align-items: center;
  }
}

.switch{
  display: none;
  @media (max-width: 1041px) {
    display: block;
   }
}