:root {
  --color-accent: #03AED2;
  --color-accent-transparent: rgba(3, 174, 210, 0.5);
  --color-accent-hover: #0091af;
  --color-primary: #0b1f35;
  --color-secondary: #546272;
  --color-secondary-75: #5a5758;
  --color-secondary-50: #918f90;
  --color-secondary-35: #b2b1b1;
  --color-secondary-25: #c8c7c7;
  --color-secondary-10: #e9e9e9;

  --color-tertiary: #b6bcc3;
  --color-disabled: #dbdee1;
  --color-primary-inverted: #fff;

  --color-negative-accent: #f6392d;
  --color-error: #d91d0b;
  --color-brand-bg: #eef8ee;
  --color-bg: #f3f4f5;
  --color-yelow: #fab040;

  --color-blue: #007aff;
  --color-blue-hover: #0055b3;
  --color-blue-bg: #e6f2ff;

  --color-magento: #ed008c;
  --color-purple: #812ad8;
  --color-turquise: #00bfbf;
  --color-subscription: #2c8ffb;

  --transition-timeout: 0.25s;
}

@mixin scrollBar {
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #E9E9E9FF;
    border-radius: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background: #C8C7C7FF;
    border-radius: 0.5rem;
  }
}