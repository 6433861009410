.sidebar {
  height: 100%;
  width: 274px !important;
  min-width: 274px !important;
  border-radius: 16px;
  background: #fff;
  padding: 25px 30px 30px;
  box-sizing: border-box;
  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .user {
    padding: 15px 0;
    display: flex;
    align-items: center;
    gap: 8px;

    .profile {
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }

    .info {
      overflow: hidden;

      .name {
        color: #444;
        font-size: 14px;
        font-weight: 300;
        line-height: 140%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .email {
        color: #c2bebe;
        font-size: 14px;
        font-weight: 300;
        line-height: 140%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    @media (max-width: 1024px) {
      display: none;
    }
  }

  .menu {
    display: block;
    width: 110%;
    margin-left: -10px;
    margin-top: 5px;
    border: none !important;

    li {
      padding: 0 0 0 12px !important;
    }
    @media (max-width: 1024px) {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
}
