html {
  scroll-behavior: smooth;
}

.root {
  background: #f3f5fc;
  min-height: 100vh;
  font-family: Inter, sans-serif;
  font-style: normal;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1336px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.bg {
  height: calc(100vh - 73px - 22px - 50px);
  background: url('../../../public/login_bg.png') top right / cover no-repeat;

  @media screen and (max-width: 425px) {
    background: #fff;
  }
}

.content {
  border-radius: 15px;
  background: #fff;
  max-width: fit-content;
  margin: 95px auto;
  padding: 75px;

  @media screen and (max-width: 767px) {
    padding: 25px;
  }
}
